const productsList = [
    {
        "description": "BABY CARROT PUNNET 200G SOUTH AFRICAN",
        "category": "BABY VEGETABLES"
    },
    {
        "description": "BABY CORN 12 X 80G",
        "category": "BABY VEGETABLES"
    },
    {
        "description": "CUCUMBER BABY X 32",
        "category": "BABY VEGETABLES"
    },
    {
        "description": "BREAD CRUMPETS",
        "category": "BREAD"
    },
    {
        "description": "BREAD HOVIS GRANARY THICK SLICED",
        "category": "BREAD"
    },
    {
        "description": "BREAD KINGSMILL 50/50",
        "category": "BREAD"
    },
    {
        "description": "BREAD MUFFINS",
        "category": "BREAD"
    },
    {
        "description": "BREAD SOFT THICK WHITE",
        "category": "BREAD"
    },
    {
        "description": "BREAD THICK WHOLEMEAL",
        "category": "BREAD"
    },
    {
        "description": "ALPRO SOYA YOGHURT X 500 ML",
        "category": "DAIRY"
    },
    {
        "description": "BUTTER PORTIONS 100 X 7 GM",
        "category": "DAIRY"
    },
    {
        "description": "BUTTER SALTED 40 X 250G",
        "category": "DAIRY"
    },
    {
        "description": "BUTTER UNSALTED 40 X 250G",
        "category": "DAIRY"
    },
    {
        "description": "BUTTER VEGAN X 500G",
        "category": "DAIRY"
    },
    {
        "description": "CHEESE APPLEWOOD 30G SLICES X3KG",
        "category": "DAIRY"
    },
    {
        "description": "CHEESE APPLEWOOD SMOKED VEGAN SLICES X 200G",
        "category": "DAIRY"
    },
    {
        "description": "CHEESE BUFFALO MOZZARELLA BALLS X 200 GM",
        "category": "DAIRY"
    },
    {
        "description": "CHEESE COTSWOLD BLUE X 300G",
        "category": "DAIRY"
    },
    {
        "description": "CHEESE EMMENTAL SLICES (50 X 20 GM) X 1 KG",
        "category": "DAIRY"
    },
    {
        "description": "CHEESE FETA GREEK X 900 GM",
        "category": "DAIRY"
    },
    {
        "description": "CHEESE FULL FAT SOFT X 2 KG",
        "category": "DAIRY"
    },
    {
        "description": "CHEESE GOATS LOG X 1 KG",
        "category": "DAIRY"
    },
    {
        "description": "CHEESE GOUDA NATURE X 1KG",
        "category": "DAIRY"
    },
    {
        "description": "CHEESE GRANA PADANO PARMESAN X 1 KG",
        "category": "DAIRY"
    },
    {
        "description": "CHEESE GRATED CHEDDAR X 1 KG",
        "category": "DAIRY"
    },
    {
        "description": "CHEESE HALLOUMI X 250 GM",
        "category": "DAIRY"
    },
    {
        "description": "CHEESE MASCARPONE X 2KG",
        "category": "DAIRY"
    },
    {
        "description": "CHEESE MILD CHEDDAR X 5 KG",
        "category": "DAIRY"
    },
    {
        "description": "CHEESE MONTEREY JACK GRATED 6X2KG",
        "category": "DAIRY"
    },
    {
        "description": "CHEESE MONTEREY JACK SLICES 20GM X 1KG",
        "category": "DAIRY"
    },
    {
        "description": "CHEESE MOZZARELLA BOCCONCINI X 1 KG",
        "category": "DAIRY"
    },
    {
        "description": "CHEESE MOZZARELLA GRATED X 2 KG",
        "category": "DAIRY"
    },
    {
        "description": "CHEESE PARMESAN FLAKES X 1 KG",
        "category": "DAIRY"
    },
    {
        "description": "CHEESE QUARTERS BLUE X 2.3 KG LONG CLAWSON",
        "category": "DAIRY"
    },
    {
        "description": "CHEESE RED LEICESTER X 2.5 KG",
        "category": "DAIRY"
    },
    {
        "description": "CHEESE RICOTTA X 1.5 KG",
        "category": "DAIRY"
    },
    {
        "description": "CHEESE ROQUEFORT WEDGE 100G",
        "category": "DAIRY"
    },
    {
        "description": "CHEESE SLICES MILD 20 GM X 1 KG",
        "category": "DAIRY"
    },
    {
        "description": "CHEESE STILTON WEDGES 200G",
        "category": "DAIRY"
    },
    {
        "description": "CREAM CLOTTED X 454 GM",
        "category": "DAIRY"
    },
    {
        "description": "CREAM DOUBLE X 2.27 LTR",
        "category": "DAIRY"
    },
    {
        "description": "CREAM SET SOUR X 2 KG",
        "category": "DAIRY"
    },
    {
        "description": "CREAM WHIPPING X 2.27 LTR",
        "category": "DAIRY"
    },
    {
        "description": "CREME FRAICHE X 2 KG",
        "category": "DAIRY"
    },
    {
        "description": "EGG WHITE PASTEURISED X 1 LTR",
        "category": "DAIRY"
    },
    {
        "description": "EGG WHOLE PASTEURISED  X 1 LTR",
        "category": "DAIRY"
    },
    {
        "description": "EGG YOLK PASTEURISED X 1 LTR",
        "category": "DAIRY"
    },
    {
        "description": "EGGS CLARENCE COURT BURFORD BROWN X 15 DOZEN",
        "category": "DAIRY"
    },
    {
        "description": "EGGS DUCK X 20",
        "category": "DAIRY"
    },
    {
        "description": "EGGS FREE RANGE MEDIUM LION X 15 DOZEN",
        "category": "DAIRY"
    },
    {
        "description": "EGGS MEDIUM (HAPPY EGGS) X 10 DOZEN",
        "category": "DAIRY"
    },
    {
        "description": "FERME DES PEUPLLERS FRUIT 12X125G",
        "category": "DAIRY"
    },
    {
        "description": "FLORA LIGHT SPREAD X 2KG",
        "category": "DAIRY"
    },
    {
        "description": "GRAPEFRUIT JUICE 12 X 1LTR",
        "category": "DAIRY"
    },
    {
        "description": "GREEK YOGURT KOLIOS X 1 KG",
        "category": "DAIRY"
    },
    {
        "description": "JUICE LEMON JOHNSONS CP X 1LTR",
        "category": "DAIRY"
    },
    {
        "description": "JUICE LIME JOHNSONS CP X 1LTR",
        "category": "DAIRY"
    },
    {
        "description": "JUICE ORANGE 12 X 1 LTR",
        "category": "DAIRY"
    },
    {
        "description": "JUICE ORANGE SMOOTH X 2.27 LTR",
        "category": "DAIRY"
    },
    {
        "description": "MILK BUTTERMILK X 5 LTR",
        "category": "DAIRY"
    },
    {
        "description": "MILK FULL FAT X 2 LTR",
        "category": "DAIRY"
    },
    {
        "description": "MILK ORGANIC SEMI SKIMMED (GREEN) X 2 LTR",
        "category": "DAIRY"
    },
    {
        "description": "MILK ORGANIC WHOLE (BLUE) 2 LITRE",
        "category": "DAIRY"
    },
    {
        "description": "MILK PERGAL SEMI SKIMMED X 24 PINT",
        "category": "DAIRY"
    },
    {
        "description": "MILK SEMI SKIMMED X 1 PINT",
        "category": "DAIRY"
    },
    {
        "description": "MILK SEMI SKIMMED X 2 LTR",
        "category": "DAIRY"
    },
    {
        "description": "MILK SKIMMED X 2 LTR",
        "category": "DAIRY"
    },
    {
        "description": "PLENISH ALMOND MILK X 1L",
        "category": "DAIRY"
    },
    {
        "description": "PLENISH COCONUT MILK X 1L",
        "category": "DAIRY"
    },
    {
        "description": "PLENISH HAZELNUT MILK X 1L",
        "category": "DAIRY"
    },
    {
        "description": "PLENISH OAT MILK X 1L",
        "category": "DAIRY"
    },
    {
        "description": "PLENISH SOYA MILK X 1L",
        "category": "DAIRY"
    },
    {
        "description": "UHT AEROSOL CREAM X 500 ML",
        "category": "DAIRY"
    },
    {
        "description": "UHT APPLE JUICE 12 X 1LTR",
        "category": "DAIRY"
    },
    {
        "description": "UHT PINEAPPLE JUICE 12 X 1LTR",
        "category": "DAIRY"
    },
    {
        "description": "YOGHURT COCONUT COYO X 2 KG",
        "category": "DAIRY"
    },
    {
        "description": "YOGURT NATURAL 12 X 125 GM",
        "category": "DAIRY"
    },
    {
        "description": "YOGURT NATURAL X 400 GM",
        "category": "DAIRY"
    },
    {
        "description": "ACHIOTE PASTE X 1KG",
        "category": "DRY GOODS"
    },
    {
        "description": "AGAVE NECTAR X 360 ML",
        "category": "DRY GOODS"
    },
    {
        "description": "ALUMINIUM FOIL 450MM X 75M",
        "category": "DRY GOODS"
    },
    {
        "description": "ANCHOVIES IN SUNFLOWER OIL X 365 GM",
        "category": "DRY GOODS"
    },
    {
        "description": "ARBORIO RISOTTO RICE X 1KG",
        "category": "DRY GOODS"
    },
    {
        "description": "BAKED BEANS COUNTRY RANGE X 2.62 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "BAKING PARCHMENT 450 MM X 50 M",
        "category": "DRY GOODS"
    },
    {
        "description": "BAKING POWDER X 800 GM",
        "category": "DRY GOODS"
    },
    {
        "description": "BALSAMIC VINEGAR X 5 LTR",
        "category": "DRY GOODS"
    },
    {
        "description": "BASMATI RICE 20KG",
        "category": "DRY GOODS"
    },
    {
        "description": "BASMATI RICE X 5 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "BLACK TRUFFLE OIL X 250 ML",
        "category": "DRY GOODS"
    },
    {
        "description": "BROWN RICE 5KG",
        "category": "DRY GOODS"
    },
    {
        "description": "CAESAR DRESSING X 2.27 LITRE",
        "category": "DRY GOODS"
    },
    {
        "description": "CANNELLINI BEANS X 2.5 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "CAPERS IN BRINE X 2 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "CAPERS X 800G",
        "category": "DRY GOODS"
    },
    {
        "description": "CASTER SUGAR X 2 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "CHESTNUT VACUUM PACK X 400 GM",
        "category": "DRY GOODS"
    },
    {
        "description": "CHICK PEAS X 2.5 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "CHILLI CRUSHED FLAKES X 400G",
        "category": "DRY GOODS"
    },
    {
        "description": "CHILLI WHOLE DRIED X 1 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "CHINESE FIVE SPICE X 440 GM",
        "category": "DRY GOODS"
    },
    {
        "description": "CHIPOTLE IN ADOBO X 2.8 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "CHIPOTLE PASTE SANTA MARIA X 750G",
        "category": "DRY GOODS"
    },
    {
        "description": "CHOCOLATE DESSERT SAUCE X 500 GM",
        "category": "DRY GOODS"
    },
    {
        "description": "CHOCOLATE DROPS MILK CHOCOLATE X 2.5 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "CHOPPED TOMATOES X 2.5 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "CINNAMON GROUND X 450G",
        "category": "DRY GOODS"
    },
    {
        "description": "CLING FILM 450MM X 300M",
        "category": "DRY GOODS"
    },
    {
        "description": "COOKING RED WINE X 3 LTR",
        "category": "DRY GOODS"
    },
    {
        "description": "CORN TORTILLA 12CM (18X20)",
        "category": "DRY GOODS"
    },
    {
        "description": "CORNFLOUR X 3 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "CUMIN GROUND X 450 GM",
        "category": "DRY GOODS"
    },
    {
        "description": "CUMIN SEEDS WHOLE X 400 GM",
        "category": "DRY GOODS"
    },
    {
        "description": "ENGLISH MUSTARD X 2 LTR",
        "category": "DRY GOODS"
    },
    {
        "description": "FLOUR GRAM CHICKPEA X 1 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "FLOUR PLAIN 16KG",
        "category": "DRY GOODS"
    },
    {
        "description": "FRANKS RED HOT ORIGINAL SAUCE 3.7L",
        "category": "DRY GOODS"
    },
    {
        "description": "GARLIC POWDER X 500 GM",
        "category": "DRY GOODS"
    },
    {
        "description": "GHERKINS X 2.4 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "GNOCCHI DI PATATA X 500G",
        "category": "DRY GOODS"
    },
    {
        "description": "GRANULATED SUGAR X 25 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "GREEN PEPPERCORNS IN BRINE X 750 GM",
        "category": "DRY GOODS"
    },
    {
        "description": "GROUND ARROWROOT X 500G",
        "category": "DRY GOODS"
    },
    {
        "description": "HARICOT BEANS 2KG",
        "category": "DRY GOODS"
    },
    {
        "description": "HARISSA PASTE X 380 GM",
        "category": "DRY GOODS"
    },
    {
        "description": "HEINZ KETCHUP SACHETS X 200",
        "category": "DRY GOODS"
    },
    {
        "description": "HEINZ MAYONNAISE SACHETS X 200",
        "category": "DRY GOODS"
    },
    {
        "description": "HEINZ TOMATO KETCHUP X 2.15LTR",
        "category": "DRY GOODS"
    },
    {
        "description": "HELLMANS MAYONNAISE 430ML",
        "category": "DRY GOODS"
    },
    {
        "description": "HOLLANDAISE SAUCE X 1 LTR",
        "category": "DRY GOODS"
    },
    {
        "description": "HORCHATA VANILLA FLAVOUR X 750ML",
        "category": "DRY GOODS"
    },
    {
        "description": "HP BROWN SAUCE X 2.3 LTR",
        "category": "DRY GOODS"
    },
    {
        "description": "ICING SUGAR X 1 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "JALAPENOS GREEN SLICED A10 X 3KG",
        "category": "DRY GOODS"
    },
    {
        "description": "JUNIPER BERRIES X 1 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "LIGHT SOY SAUCE 500ML",
        "category": "DRY GOODS"
    },
    {
        "description": "LKK HOI SIN SAUCE X 2.3 LTR",
        "category": "DRY GOODS"
    },
    {
        "description": "MALDON SEA SALT X 1.4 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "MAPLE SYRUP X 500 ML",
        "category": "DRY GOODS"
    },
    {
        "description": "MEECHUN SESAME SAUCE X 24",
        "category": "DRY GOODS"
    },
    {
        "description": "MILK COCONUT X 400 ML",
        "category": "DRY GOODS"
    },
    {
        "description": "MILK CONDENSED X 397 GM",
        "category": "DRY GOODS"
    },
    {
        "description": "MILK EVAPORATED X 410GM",
        "category": "DRY GOODS"
    },
    {
        "description": "MUSHROOM WILD MIX DRIED X 500 GM",
        "category": "DRY GOODS"
    },
    {
        "description": "OLIVE BLACK PLAIN PITTED 3KG",
        "category": "DRY GOODS"
    },
    {
        "description": "OLIVE HERB & GARLIC X 5KG",
        "category": "DRY GOODS"
    },
    {
        "description": "OLIVE OIL EXTRA VIRGIN X 5 LTR",
        "category": "DRY GOODS"
    },
    {
        "description": "OLIVES KALAMATA PITTED 2.6KG ",
        "category": "DRY GOODS"
    },
    {
        "description": "ONION POWDER 1KG",
        "category": "DRY GOODS"
    },
    {
        "description": "OREGANO DRIED X 150 GM",
        "category": "DRY GOODS"
    },
    {
        "description": "PANKO BREADCRUMBS X 10 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "PAPRIKA GROUND X 500 GM",
        "category": "DRY GOODS"
    },
    {
        "description": "PEKA MASHED POTATO X 2 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "PEPPERCORN BLACK CRACKED X 450 GM",
        "category": "DRY GOODS"
    },
    {
        "description": "PEPPERCORN BLACK GROUND X 500 GM",
        "category": "DRY GOODS"
    },
    {
        "description": "PEPPERCORN WHITE GROUND X 600GM",
        "category": "DRY GOODS"
    },
    {
        "description": "PINEAPPLE SLICES TINNED X 3 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "PINENUTS X 1 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "PIPERS CRISPS ANGLESEY SEA SALT 40X40G ",
        "category": "DRY GOODS"
    },
    {
        "description": "PIQUILLO PEPPERS 390G ",
        "category": "DRY GOODS"
    },
    {
        "description": "PLAIN FLOUR X 1.5 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "PLUM TOMATOES TINNED X 2.5 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "POLENTA X 1 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "POMEGRANATE MOLASSES X 300 ML",
        "category": "DRY GOODS"
    },
    {
        "description": "POTATO STARCH 25KG",
        "category": "DRY GOODS"
    },
    {
        "description": "PUMPKIN SEEDS X 1 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "RAPESEED OIL X 20 LTR",
        "category": "DRY GOODS"
    },
    {
        "description": "RED KIDNEY BEANS X 2.5 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "RICE TAIKENG NO.9 DIN TAI FUNG (8 X 2.5KG)",
        "category": "DRY GOODS"
    },
    {
        "description": "RICE VINEGAR MIZKAN X 710 ML",
        "category": "DRY GOODS"
    },
    {
        "description": "SALSA BRAVA 6 X 2KG",
        "category": "DRY GOODS"
    },
    {
        "description": "SALSA VERDE 6 X 2KG",
        "category": "DRY GOODS"
    },
    {
        "description": "SALT TABLE X 6 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "SELF RAISING FLOUR X 1.5 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "SESAME OIL X 2 LTR",
        "category": "DRY GOODS"
    },
    {
        "description": "SPELT 1KG",
        "category": "DRY GOODS"
    },
    {
        "description": "SRIRACHA SAUCE 730 ML",
        "category": "DRY GOODS"
    },
    {
        "description": "SUGAR SOFT DARK BROWN X 3 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "SUGAR SOFT LIGHT BROWN X 3 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "SUMACH 250G",
        "category": "DRY GOODS"
    },
    {
        "description": "SUN DRIED TOMATO X 1 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "SUNFLOWER SEEDS X 1 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "SWEET CHILLI DIPPING SAUCE X 1 LTR",
        "category": "DRY GOODS"
    },
    {
        "description": "SWEETCORN TINNED 2.12KG",
        "category": "DRY GOODS"
    },
    {
        "description": "SZECHUAN PEPPERCORNS X 500 GM",
        "category": "DRY GOODS"
    },
    {
        "description": "TAHINI PASTE X 1 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "THAI SQUID FISH SAUCE X 725ML",
        "category": "DRY GOODS"
    },
    {
        "description": "TINNED ROASTED RED PEPPERS X 3 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "TOMATO PUREE X 800 GM",
        "category": "DRY GOODS"
    },
    {
        "description": "TORTILLA WRAPS 25CM X 18",
        "category": "DRY GOODS"
    },
    {
        "description": "TORTILLA WRAPS 30CM (6 X 18)",
        "category": "DRY GOODS"
    },
    {
        "description": "TORTILLA YELLOW CORN 6 INC",
        "category": "DRY GOODS"
    },
    {
        "description": "TRACKLEMENTS FRENCH DIJON MUSTARD 1.2KG",
        "category": "DRY GOODS"
    },
    {
        "description": "VANILLA ESSENCE X 500ML",
        "category": "DRY GOODS"
    },
    {
        "description": "WALNUT HALVES X 1 KG",
        "category": "DRY GOODS"
    },
    {
        "description": "WHITE WINE VINEGAR X 5 LTR",
        "category": "DRY GOODS"
    },
    {
        "description": "XIAN SOYBEAN",
        "category": "DRY GOODS"
    },
    {
        "description": "FLATBREAD TRAD GREEK 18CM  1X10EA ",
        "category": "FROZEN"
    },
    {
        "description": "FROZEN ARTICHOKE X 1 KG",
        "category": "FROZEN"
    },
    {
        "description": "FROZEN BURGER BUNS X 48",
        "category": "FROZEN"
    },
    {
        "description": "FROZEN CHOPPED SPINACH X 1KG",
        "category": "FROZEN"
    },
    {
        "description": "FROZEN EDAMAME (NOT IN SHELL) X 500G",
        "category": "FROZEN"
    },
    {
        "description": "FROZEN EDAMAME IN SHELLS X 500G",
        "category": "FROZEN"
    },
    {
        "description": "FROZEN GARDEN PEAS 10 X 1 KG",
        "category": "FROZEN"
    },
    {
        "description": "FROZEN KOFFMAN LES POMMES FRITES 10MM",
        "category": "FROZEN"
    },
    {
        "description": "FROZEN MCCAIN BEEF STEAK CHIPS (4 X 2.27 KG)",
        "category": "FROZEN"
    },
    {
        "description": "FROZEN MCCAIN HASH BROWNS X 8KG",
        "category": "FROZEN"
    },
    {
        "description": "FROZEN MCCAIN JULIENNE (6 X 2.49 KG)",
        "category": "FROZEN"
    },
    {
        "description": "FROZEN MIXED BERRIES X 1 KG",
        "category": "FROZEN"
    },
    {
        "description": "FROZEN PASTRY FILO FROZEN 10 X 400 GM",
        "category": "FROZEN"
    },
    {
        "description": "FROZEN PUFF PASTRY 4 X 1.5 KG",
        "category": "FROZEN"
    },
    {
        "description": "FROZEN PUREE MANGO X 1 KG",
        "category": "FROZEN"
    },
    {
        "description": "FROZEN PUREE PASSION FRUIT X 1 KG",
        "category": "FROZEN"
    },
    {
        "description": "FROZEN PUREE PEACH X 1 KG",
        "category": "FROZEN"
    },
    {
        "description": "FROZEN PUREE RASPBERRY X 1 KG",
        "category": "FROZEN"
    },
    {
        "description": "FROZEN PUREE STRAWBERRY X 1 KG",
        "category": "FROZEN"
    },
    {
        "description": "FROZEN SPINACH LEAF BLOCK 4 X 2.5 KG",
        "category": "FROZEN"
    },
    {
        "description": "FROZEN SWEET CORN X 1 KG",
        "category": "FROZEN"
    },
    {
        "description": "ST PIERRE BRIOCHE BUNS 7X6 300GM",
        "category": "FROZEN"
    },
    {
        "description": "SWEET POTATO FRIES (4X2.5KG)",
        "category": "FROZEN"
    },
    {
        "description": "APPLE COOKING BRAMLEY X 13.6KG",
        "category": "FRUIT"
    },
    {
        "description": "APPLE GRANNY SMITH X 12KG",
        "category": "FRUIT"
    },
    {
        "description": "APPLE PINK LADY X 12KG",
        "category": "FRUIT"
    },
    {
        "description": "APPLE RED DELICIOUS X 12KG",
        "category": "FRUIT"
    },
    {
        "description": "APRICOT X 5KG",
        "category": "FRUIT"
    },
    {
        "description": "BANANA SMALL X 13KG",
        "category": "FRUIT"
    },
    {
        "description": "BANANA X 18KG",
        "category": "FRUIT"
    },
    {
        "description": "BLACKBERRY X 125G",
        "category": "FRUIT"
    },
    {
        "description": "BLUEBERRY X 125G",
        "category": "FRUIT"
    },
    {
        "description": "CLEMENTINES X 10KG",
        "category": "FRUIT"
    },
    {
        "description": "DATES MEDJOOL X 5KG",
        "category": "FRUIT"
    },
    {
        "description": "FIGS X 18",
        "category": "FRUIT"
    },
    {
        "description": "GRAPEFRUIT PINK X 50",
        "category": "FRUIT"
    },
    {
        "description": "GRAPES BLACK SEEDLESS X 5KG",
        "category": "FRUIT"
    },
    {
        "description": "GRAPES WHITE SEEDLESS X 5KG",
        "category": "FRUIT"
    },
    {
        "description": "KIWI FRUIT X 36",
        "category": "FRUIT"
    },
    {
        "description": "KUMQUAT X 2KG",
        "category": "FRUIT"
    },
    {
        "description": "LEMONS X 120",
        "category": "FRUIT"
    },
    {
        "description": "LIMES X 60",
        "category": "FRUIT"
    },
    {
        "description": "MANGO X 9",
        "category": "FRUIT"
    },
    {
        "description": "MELON CANTALOUPE X 6",
        "category": "FRUIT"
    },
    {
        "description": "MELON HONEYDEW X 9",
        "category": "FRUIT"
    },
    {
        "description": "MELON WATER X 6",
        "category": "FRUIT"
    },
    {
        "description": "ORANGE LARGE X 56",
        "category": "FRUIT"
    },
    {
        "description": "ORANGE MEDIUM X 88",
        "category": "FRUIT"
    },
    {
        "description": "ORANGE SMALL X 100",
        "category": "FRUIT"
    },
    {
        "description": "PASSION FRUIT X 42",
        "category": "FRUIT"
    },
    {
        "description": "PAW PAW X 9",
        "category": "FRUIT"
    },
    {
        "description": "PEACHES X 24",
        "category": "FRUIT"
    },
    {
        "description": "PEAR CONFERENCE X 12KG",
        "category": "FRUIT"
    },
    {
        "description": "PHYSALIS X 100G",
        "category": "FRUIT"
    },
    {
        "description": "PINEAPPLE EXTRA SWEET X 8",
        "category": "FRUIT"
    },
    {
        "description": "PLUMS X 5KG",
        "category": "FRUIT"
    },
    {
        "description": "POMEGRANATE X 12",
        "category": "FRUIT"
    },
    {
        "description": "RASPBERRY X 125G",
        "category": "FRUIT"
    },
    {
        "description": "REDCURRANTS X 1.5KG",
        "category": "FRUIT"
    },
    {
        "description": "STRAWBERRIES X 500G",
        "category": "FRUIT"
    },
    {
        "description": "BASIL 100 GM",
        "category": "HERBS"
    },
    {
        "description": "BAYLEAF 50GM",
        "category": "HERBS"
    },
    {
        "description": "CHIVES 100 GM",
        "category": "HERBS"
    },
    {
        "description": "CORIANDER 100 GM",
        "category": "HERBS"
    },
    {
        "description": "CORIANDER X 1KG",
        "category": "HERBS"
    },
    {
        "description": "DILL 100 GM",
        "category": "HERBS"
    },
    {
        "description": "MINT 100 GM",
        "category": "HERBS"
    },
    {
        "description": "OREGANO 50 GM",
        "category": "HERBS"
    },
    {
        "description": "PARSLEY CONTINENTAL 100 GM",
        "category": "HERBS"
    },
    {
        "description": "ROSEMARY 100 GM",
        "category": "HERBS"
    },
    {
        "description": "SAGE 100 GM",
        "category": "HERBS"
    },
    {
        "description": "TARRAGON 100 GM",
        "category": "HERBS"
    },
    {
        "description": "THAI BASIL 100 GM",
        "category": "HERBS"
    },
    {
        "description": "THYME 100 GM",
        "category": "HERBS"
    },
    {
        "description": "MICRO CRESS CORIANDER",
        "category": "MICRO & CRESS"
    },
    {
        "description": "MICRO CRESS GREEN BASIL",
        "category": "MICRO & CRESS"
    },
    {
        "description": "MICRO CRESS PARSLEY",
        "category": "MICRO & CRESS"
    },
    {
        "description": "MICRO CRESS PEASHOOTS",
        "category": "MICRO & CRESS"
    },
    {
        "description": "MICRO CRESS RED AMARANTH",
        "category": "MICRO & CRESS"
    },
    {
        "description": "MICRO CRESS WATERCRESS",
        "category": "MICRO & CRESS"
    },
    {
        "description": "MICRO RED BUTTERFLY SORREL",
        "category": "MICRO & CRESS"
    },
    {
        "description": "MICRO TENDRIL PEASHOOTS",
        "category": "MICRO & CRESS"
    },
    {
        "description": "RED SHISO LEAF",
        "category": "MICRO & CRESS"
    },
    {
        "description": "VIOLA FLOWERS",
        "category": "MICRO & CRESS"
    },
    {
        "description": "MUSHROOM BUTTON X 2.5KG",
        "category": "MUSHROOMS"
    },
    {
        "description": "MUSHROOM CHESTNUT X 2.5KG",
        "category": "MUSHROOMS"
    },
    {
        "description": "MUSHROOM ENOKI 100g",
        "category": "MUSHROOMS"
    },
    {
        "description": "MUSHROOM FLAT X 1.5KG",
        "category": "MUSHROOMS"
    },
    {
        "description": "MUSHROOM GIROLLE",
        "category": "MUSHROOMS"
    },
    {
        "description": "MUSHROOM GREY OYSTER X 1.5KG",
        "category": "MUSHROOMS"
    },
    {
        "description": "MUSHROOM PORTOBELLO X 1.5KG",
        "category": "MUSHROOMS"
    },
    {
        "description": "MUSHROOM SHITAKE X 1.5KG",
        "category": "MUSHROOMS"
    },
    {
        "description": "BANANA LEAF 2 X 500G",
        "category": "OTHER PRODUCTS"
    },
    {
        "description": "HELLMANS VEGAN MAYONNAISE X 5 LTR",
        "category": "OTHER PRODUCTS"
    },
    {
        "description": "HOUMOUS 1KG",
        "category": "OTHER PRODUCTS"
    },
    {
        "description": "MAYONNAISE HELLMANS X 10 LTR",
        "category": "OTHER PRODUCTS"
    },
    {
        "description": "MAYONNAISE X 5 LTR",
        "category": "OTHER PRODUCTS"
    },
    {
        "description": "PASTA LINGUINI FRESH (LI01Q) X 1 KG",
        "category": "OTHER PRODUCTS"
    },
    {
        "description": "PASTA TORTELLONI WILD MUSH & RICOT 1KG",
        "category": "OTHER PRODUCTS"
    },
    {
        "description": "TOFU X 450 GM",
        "category": "OTHER PRODUCTS"
    },
    {
        "description": "YEAST X 500 GM",
        "category": "OTHER PRODUCTS"
    },
    {
        "description": "DICED ROOT VEG 2.5KG",
        "category": "PREPARED"
    },
    {
        "description": "POMEGRANATE SEEDS X 500G",
        "category": "PREPARED"
    },
    {
        "description": "PREP ARTICHOKE JERUSALEM PEELED 1 KG",
        "category": "PREPARED"
    },
    {
        "description": "PREP CABBAGE RED SHREDDED 1 KG",
        "category": "PREPARED"
    },
    {
        "description": "PREP COLESLAW MIX (NO ONION) 2.5KG",
        "category": "PREPARED"
    },
    {
        "description": "PREP KALE WASHED 1KG",
        "category": "PREPARED"
    },
    {
        "description": "PREP POTATO PEELED X 5 KG",
        "category": "PREPARED"
    },
    {
        "description": "PREP POTATO RED PEELED X 5 KG",
        "category": "PREPARED"
    },
    {
        "description": "PREP SHALLOT BANANA PEELED X 1 KG",
        "category": "PREPARED"
    },
    {
        "description": "PREP WATERMELON JUICE X 1LTR",
        "category": "PREPARED"
    },
    {
        "description": "AVOCADO HASS X 18",
        "category": "SALADS"
    },
    {
        "description": "BEANSHOOT LOOSE X 4KG",
        "category": "SALADS"
    },
    {
        "description": "BOK CHOI X 8KG",
        "category": "SALADS"
    },
    {
        "description": "BRYAN’S 500GM WASH CHOPPED COS 8 X 500 GM",
        "category": "SALADS"
    },
    {
        "description": "CELERY X 15",
        "category": "SALADS"
    },
    {
        "description": "CHINESE LEAF X 8",
        "category": "SALADS"
    },
    {
        "description": "CUCUMBER X 14",
        "category": "SALADS"
    },
    {
        "description": "LETTUCE BABY MIXED SALAD X 1KG",
        "category": "SALADS"
    },
    {
        "description": "LETTUCE CORN SALAD",
        "category": "SALADS"
    },
    {
        "description": "LETTUCE COS X 10",
        "category": "SALADS"
    },
    {
        "description": "LETTUCE FRISEE X 10",
        "category": "SALADS"
    },
    {
        "description": "LETTUCE ICEBERG X 12",
        "category": "SALADS"
    },
    {
        "description": "LETTUCE LITTLE GEM X 10",
        "category": "SALADS"
    },
    {
        "description": "LETTUCE LOLLO ROSSO X 12",
        "category": "SALADS"
    },
    {
        "description": "LETTUCE RADICHIO X 9",
        "category": "SALADS"
    },
    {
        "description": "LETTUCE ROQUETTE WILD X 1KG",
        "category": "SALADS"
    },
    {
        "description": "LETTUCE ROUND X 12",
        "category": "SALADS"
    },
    {
        "description": "PEPPER GREEN X 5KG",
        "category": "SALADS"
    },
    {
        "description": "PEPPER RED X 5KG",
        "category": "SALADS"
    },
    {
        "description": "PEPPER YELLOW X 5KG",
        "category": "SALADS"
    },
    {
        "description": "RADISH BREAKFAST",
        "category": "SALADS"
    },
    {
        "description": "RADISH BUNCH",
        "category": "SALADS"
    },
    {
        "description": "RADISH PREPACK X 20",
        "category": "SALADS"
    },
    {
        "description": "RAINBOW CHARD X 4KG",
        "category": "SALADS"
    },
    {
        "description": "ROQUETTE PRE PACK X 1KG",
        "category": "SALADS"
    },
    {
        "description": "SAMPHIRE X 1KG",
        "category": "SALADS"
    },
    {
        "description": "SPINACH BABY POUSSE 2 X 500 GM",
        "category": "SALADS"
    },
    {
        "description": "SPINACH LARGE LEAF X 5KG",
        "category": "SALADS"
    },
    {
        "description": "TOMATO BEEF X 7KG",
        "category": "SALADS"
    },
    {
        "description": "TOMATO CHERRY RED 9 X 250G",
        "category": "SALADS"
    },
    {
        "description": "TOMATO CHERRY VINE X 3KG",
        "category": "SALADS"
    },
    {
        "description": "TOMATO CHERRY YELLOW 9 X 250G",
        "category": "SALADS"
    },
    {
        "description": "TOMATO HERITAGE MIX X 3KG",
        "category": "SALADS"
    },
    {
        "description": "TOMATO MM X 6KG",
        "category": "SALADS"
    },
    {
        "description": "TOMATO PLUM X 6KG",
        "category": "SALADS"
    },
    {
        "description": "TOMATO SALAD M X 6KG",
        "category": "SALADS"
    },
    {
        "description": "TOMATO VINE X 5KG",
        "category": "SALADS"
    },
    {
        "description": "WATERCRESS X 15",
        "category": "SALADS"
    },
    {
        "description": "2I MINI BUNS X30",
        "category": "THE BREAD FACTORY 48H"
    },
    {
        "description": "BAGUETE",
        "category": "THE BREAD FACTORY 48H"
    },
    {
        "description": "BAGUETTE ARTISANAL",
        "category": "THE BREAD FACTORY 48H"
    },
    {
        "description": "BRIOCHE 600G CLOSED TIN (48H)",
        "category": "THE BREAD FACTORY 48H"
    },
    {
        "description": "BROWNIE W/F",
        "category": "THE BREAD FACTORY 48H"
    },
    {
        "description": "BUN 2I PLANTBASED",
        "category": "THE BREAD FACTORY 48H"
    },
    {
        "description": "BURGER CREAM 4IN SLICED X15 48H",
        "category": "THE BREAD FACTORY 48H"
    },
    {
        "description": "BURGER PLANTBASED 3.5' X30 STA",
        "category": "THE BREAD FACTORY 48H"
    },
    {
        "description": "ENGLISH MUFFIN SKILLET MEDIUM X5 (48H)",
        "category": "THE BREAD FACTORY 48H"
    },
    {
        "description": "PLAIN BAGEL X6",
        "category": "THE BREAD FACTORY 48H"
    },
    {
        "description": "PLANT BASED 7IN ROLLS X 16",
        "category": "THE BREAD FACTORY 48H"
    },
    {
        "description": "ROLL CIABATTA LONG X 5",
        "category": "THE BREAD FACTORY 48H"
    },
    {
        "description": "ROLL CIABATTA RC5",
        "category": "THE BREAD FACTORY 48H"
    },
    {
        "description": "SOURDOUGH 1.2KG SLICED",
        "category": "THE BREAD FACTORY 48H"
    },
    {
        "description": "SOURDOUGH BOROUGH BROWN 1.2KG (48HR) SL MEDX",
        "category": "THE BREAD FACTORY 48H"
    },
    {
        "description": "SOURDOUGH MIXED OLIVE BANNETON 500G (48HR)",
        "category": "THE BREAD FACTORY 48H"
    },
    {
        "description": "SOURDOUGH WHITE 650G LONG",
        "category": "THE BREAD FACTORY 48H"
    },
    {
        "description": "SPECIALITY CAMPAILLOU 800G",
        "category": "THE BREAD FACTORY 48H"
    },
    {
        "description": "SPECIALITY CAMPAILLOU RECTANGLE 800G SL EX THICK",
        "category": "THE BREAD FACTORY 48H"
    },
    {
        "description": "ARTICHOKE JERUSALEM X 5KG",
        "category": "VEGETABLES"
    },
    {
        "description": "ASPARAGUS EXTRA LARGE X 5KG",
        "category": "VEGETABLES"
    },
    {
        "description": "AUBERGINE X 5KG",
        "category": "VEGETABLES"
    },
    {
        "description": "BEANS EXTRA FINE X 1.5KG",
        "category": "VEGETABLES"
    },
    {
        "description": "BEETROOT CANDY X 10KG",
        "category": "VEGETABLES"
    },
    {
        "description": "BEETROOT PRE PACK x [18X250G]",
        "category": "VEGETABLES"
    },
    {
        "description": "BEETROOT RAW X 10KG",
        "category": "VEGETABLES"
    },
    {
        "description": "BROCCOLI PURPLE X 4KG",
        "category": "VEGETABLES"
    },
    {
        "description": "BROCCOLI TENDERSTEM 4 X 500G",
        "category": "VEGETABLES"
    },
    {
        "description": "BROCCOLI X 6KG",
        "category": "VEGETABLES"
    },
    {
        "description": "BRUSSEL SPROUTS ENGLISH X 9KG",
        "category": "VEGETABLES"
    },
    {
        "description": "BUTTERNUT SQUASH X 10KG",
        "category": "VEGETABLES"
    },
    {
        "description": "CABBAGE HISPI X 8",
        "category": "VEGETABLES"
    },
    {
        "description": "CABBAGE RED X 25KG",
        "category": "VEGETABLES"
    },
    {
        "description": "CABBAGE SAVOY X 10",
        "category": "VEGETABLES"
    },
    {
        "description": "CABBAGE WHITE X 25KG",
        "category": "VEGETABLES"
    },
    {
        "description": "CARROT CHANTENAY X 5KG",
        "category": "VEGETABLES"
    },
    {
        "description": "CARROT DONKEY X 10KG",
        "category": "VEGETABLES"
    },
    {
        "description": "CARROT X 10KG",
        "category": "VEGETABLES"
    },
    {
        "description": "CASSAVA",
        "category": "VEGETABLES"
    },
    {
        "description": "CAULIFLOWER X 8",
        "category": "VEGETABLES"
    },
    {
        "description": "CELERIAC X 10KG",
        "category": "VEGETABLES"
    },
    {
        "description": "CHILLI GREEN X 3KG",
        "category": "VEGETABLES"
    },
    {
        "description": "CHILLI JALAPENO GREEN X 2KG",
        "category": "VEGETABLES"
    },
    {
        "description": "CHILLI RED X 3KG",
        "category": "VEGETABLES"
    },
    {
        "description": "CHILLI SCOTCH BONNET X 3KG",
        "category": "VEGETABLES"
    },
    {
        "description": "CHOW CHOW",
        "category": "VEGETABLES"
    },
    {
        "description": "CORN ON THE COB TWIN PACK 12X2'S",
        "category": "VEGETABLES"
    },
    {
        "description": "COURGETTE GREEN X 5KG",
        "category": "VEGETABLES"
    },
    {
        "description": "COURGETTE YELLOW X 4KG",
        "category": "VEGETABLES"
    },
    {
        "description": "FENNEL X 5KG",
        "category": "VEGETABLES"
    },
    {
        "description": "GARLIC LOOSE X 4KG",
        "category": "VEGETABLES"
    },
    {
        "description": "GARLIC PEELED X 1KG",
        "category": "VEGETABLES"
    },
    {
        "description": "GINGER X 10KG",
        "category": "VEGETABLES"
    },
    {
        "description": "HORSERADISH X 5KG",
        "category": "VEGETABLES"
    },
    {
        "description": "KALE X 5KG",
        "category": "VEGETABLES"
    },
    {
        "description": "LEEKS X 5KG",
        "category": "VEGETABLES"
    },
    {
        "description": "LEMONGRASS X 1KG",
        "category": "VEGETABLES"
    },
    {
        "description": "MANGE TOUT X 1.5KG",
        "category": "VEGETABLES"
    },
    {
        "description": "MOOLI X 15KG",
        "category": "VEGETABLES"
    },
    {
        "description": "ONION LARGE X 20KG",
        "category": "VEGETABLES"
    },
    {
        "description": "ONION RED X 10KG",
        "category": "VEGETABLES"
    },
    {
        "description": "ONION SMALL COOKING X 20KG",
        "category": "VEGETABLES"
    },
    {
        "description": "PARSNIP X 5KG",
        "category": "VEGETABLES"
    },
    {
        "description": "PEPPER PADRON X 2KG",
        "category": "VEGETABLES"
    },
    {
        "description": "POTATO BAKING (GRADED) 40 1 X 40",
        "category": "VEGETABLES"
    },
    {
        "description": "POTATO BAKING (GRADED) 50 1 X 50",
        "category": "VEGETABLES"
    },
    {
        "description": "POTATO BAKING (GRADED) 60 1 X 60",
        "category": "VEGETABLES"
    },
    {
        "description": "POTATO BAKING (GRADED) 80 1 X 80",
        "category": "VEGETABLES"
    },
    {
        "description": "POTATO KOFFMANS BLUE BAG X 20KG",
        "category": "VEGETABLES"
    },
    {
        "description": "POTATO MARIS PIPER X 25KG",
        "category": "VEGETABLES"
    },
    {
        "description": "POTATO MIDS X 10KG",
        "category": "VEGETABLES"
    },
    {
        "description": "POTATO RED ROOSTER X 25KG",
        "category": "VEGETABLES"
    },
    {
        "description": "POTATO SWEET X 6KG",
        "category": "VEGETABLES"
    },
    {
        "description": "ROMANESCO X 6",
        "category": "VEGETABLES"
    },
    {
        "description": "SHALLOTS BANANA X 4.5KG",
        "category": "VEGETABLES"
    },
    {
        "description": "SPRING GREEN X 5KG",
        "category": "VEGETABLES"
    },
    {
        "description": "SPRING ONION X 12",
        "category": "VEGETABLES"
    },
    {
        "description": "SUGAR SNAP PEAS X 1.5KG",
        "category": "VEGETABLES"
    },
    {
        "description": "SWEDE X 12.5KG",
        "category": "VEGETABLES"
    }
];

export default productsList;