import React from 'react'

import Layout from "../components/layout"
import Products from "../components/Products"
import Seo from "../components/seo"

const ProductsCatalogue = () => {

	return (
		<Layout>
			<Seo title="Products" />

			<Products />
		</Layout>
	)
}

export default ProductsCatalogue
